// const skinConfig = require('../../skins/' + __SKIN__ + '/config.js');
import Helpers from '../helpers/helperFunctions';
const Config = require('./conf');

Config.env = Config.env || {};

Config.env.os = Helpers.getMobileOperatingSystem();

// Helpers.mergeRecursive(Config, skinConfig);

export default Config;
