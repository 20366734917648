module.exports = {
  main: {
    site_id: 158,
    skin: 'grandbetting-esport',
    title: 'Grandbetting-Esports',
    mathCuttingFunctionForExpOdds: true,
    enablePlayerNationality: true,
    disableMultipleSingleBets: true,
    changeTimeFiltersTimeZone: '-04:00',
    changeGamesStartTimeByTimeZone: 14400,
    fractionalBaseFormat: true,
    homeAwayBaseRecalculationEnabled: true,
    decimalFormatRemove3Digit: true,
    teamLogosPath: 'https://statistics.betcoapps.com/images',
    removeDotFromVirtualKeyBoard: true,
    hasBetSlipEventsLimit: 15,
    sportsbookViews: ['classic_esport', 'asian_esport'],
    shortDateTimeFormat: 'MM/DD HH:mm',
    shortDateFormat: 'MM.DD',
    setDefaultBetSlipSettingType: true,
    clearSelectionsAfterLogOut: true,
    disableLiveGamesInToday: true,
    enableHideBalance: true,
    traditionalSportsBookUrl: 'https://m.grandbetting.com/',
    availableOddFormats: ['decimal', 'hongkong', 'malay', 'indo'],
    blockedPrices: { minPrice: 0.01, maxPrice: 1 },
    roundDecimalCoefficients: 2,
    specialOddFormat: {},
    overrideErrorMessages: { '2200': 'api_518', '2421': 'api_515' },
    indoAndMalayToHongkong: true,
    enableProfitBoost: true,
    quickBet: { hideQuickBet: true },
    enableOddsFormatInBetslip: true,
    enableBetHistoryInFooter: true,
    asianInitialTimeFilters: [0, 1, 2, 3, 4, 5, 6],
    balance: { disableDepositButton: true, disableMenu: true },
    availableLanguages: {
      '@replace': true,
      eng: { short: 'EN', full: 'English', order: 1 },
      tur: { short: 'TR', full: 'Türkçe', order: 2 },
    },
    availableCurrencies: ['TRY'],
    mainMenuItemsOrder: [
      { title: 'asian-prematch', order: 1 },
      { title: 'asian-live', order: 2 },
      { title: 'asian-esport-prematch', order: 3 },
      { title: 'asian-esport-live', order: 4 },
      { title: 'european-esport-prematch', order: 5 },
      { title: 'european-esport-live', order: 6 },
      { title: 'game-results', order: 7 },
    ],
    video: { enableForLogoutUser: true, evenIfNotLoggedIn: true },
    mainMenuItems: null,
    rightMenuContentLinks: [],
    showLiveIndicator: 'all',
    regConfig: {
      fields: {
        agree: false,
        re_captcha: {
          order: 24,
          title: 'Re Captcha',
          skippAbleForProfileValidation: true,
          name: 'g_recaptcha_response',
          placeholder: 'Enter here',
          type: 're_captcha',
          classes: 'single-form-item',
          customAttrs: { required: true },
          validationMessages: { required: 'This field is required' },
        },
      },
    },
    payments: [],
  },
  env: { lang: 'tur', oddsFormat: 'decimal' },
  isPartnerIntegration: {
    mode: { iframe: true },
    iframe: {
      disableHeader: true,
      disableNavigationMenu: false,
      disableLeftMenu: true,
      disableRightMenu: false,
      disablePopups: false,
      disableFooter: true,
    },
    discardGetBalance: true,
  },
  betting: {
    enableExpressBonus: false,
    enablePartialCashOut: false,
    enableSuperBet: false,
    totalOddsMax: 10000,
    enableOutright: true,
    enableCounterOffer: true,
  },
  cms: { baseHost: 'www.grandbetting3.com', configFromCmsLink: false },
  casino: { urlPrefix: false },
  swarm: {
    url: [{ url: 'https://eu-swarm-grandbetting.betcoswarm.com/' }],
    websocket: [{ url: 'wss://eu-swarm-grandbetting.betcoswarm.com/ ' }],
  },
  theming: {
    color1: {
      title: 'default',
      options: {
        heroColor: '#B28C32',
        skinColor: '#1b1b1b',
        brand: '#B28C32',
        textColor: '#1b1b1b',
        favoriteColor: '#B28C32',
      },
      order: 1,
    },
  },
};
