import React from 'react';

import './style.scss';

import './style.scss';

function ThemingSplashScreen() {
  return (
    <div className="loading-screen">
      <div className="line-loader-splash">
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

export default ThemingSplashScreen;
