/* eslint-disable brace-style */
import moment from 'moment';
import cookie from 'react-cookie';

import Config from 'config/conf';
import { symbolsReverseList } from 'constants/symbolsReverseList';
import { t } from 'helpers/translator';
import { AdditionalCssLoaded } from 'actions/ui';

import mergeRecursive from './mergeRecursive';

const cacheMenuItemsTitle = new Map();
const { isArray } = Array;

function isFunction(value) {
  return typeof value === 'function';
}

const Helpers = (function() {
  return {
    isBlankObject: value => {
      return value !== null && typeof value === 'object' && !Object.getPrototypeOf(value);
    },
    forEach: function(obj, iterator, context) {
      var key, length;
      if (obj) {
        if (isFunction(obj)) {
          for (key in obj) {
            // Need to check if hasOwnProperty exists,
            // as on IE8 the result of querySelectorAll is an object without a hasOwnProperty function
            if (
              key !== 'prototype' &&
              key !== 'length' &&
              key !== 'name' &&
              (!obj.hasOwnProperty || obj.hasOwnProperty(key))
            ) {
              iterator.call(context, obj[key], key, obj);
            }
          }
        } else if (isArray(obj) || isArray(obj)) {
          var isPrimitive = typeof obj !== 'object';
          for (key = 0, length = obj.length; key < length; key++) {
            if (isPrimitive || key in obj) {
              iterator.call(context, obj[key], key, obj);
            }
          }
        } else if (obj.forEach) {
          obj.forEach(iterator, context, obj);
        } else if (Helpers.isBlankObject(obj)) {
          // createMap() fast path --- Safe to avoid hasOwnProperty check because prototype chain is empty
          for (key in obj) {
            iterator.call(context, obj[key], key, obj);
          }
        } else if (typeof obj.hasOwnProperty === 'function') {
          // Slow path for objects inheriting Object.prototype, hasOwnProperty check needed
          for (key in obj) {
            if (obj.hasOwnProperty(key)) {
              iterator.call(context, obj[key], key, obj);
            }
          }
        } else {
          // Slow path for objects which do not have a method `hasOwnProperty`
          for (key in obj) {
            if (hasOwnProperty.call(obj, key)) {
              iterator.call(context, obj[key], key, obj);
            }
          }
        }
      }
      return obj;
    },
    isArraysEqual: function(array1, array2) {
      // if the other array is a falsy value, return // compare lengths - can save a lot of time
      if (!array1 || !array2 || array1.length !== array2.length) {
        return false;
      }

      for (let i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
          // recurse into the nested arrays
          if (!Helpers.isArraysEqual(array1[i], array2[i])) {
            return false;
          }
        } else if (array1[i] !== array2[i]) {
          // Warning - two different object instances will never be equal
          return false;
        }
      }
      return true;
    },
    /**
     * @name selectedTitleByGeoData
     * @description selects title by languages or country geo data
     * @param {Object} geoData
     * @return {String} skin title
     */
    selectedTitleByGeoData: function(geoData) {
      const { title } = Config.main;

      if (title && title.type) {
        switch (title.type) {
          case 'titleByCountry':
            document.title = t(title[geoData.geoData.countryCode] || title['default']);
            break;
          case 'titleByLanguage':
            document.title = t(title[geoData.lang] || title['default']);
            break;
          default:
            break;
        }
      } else {
        document.title = t(title || Config.main.skin);
      }
    },
    /**
     * @name removeStake
     * @description remove stake values after clear betslip or place bet
     * @param {Object} betslip
     */
    removeStake: function(betslip) {
      cookie.remove('stakeValue');
      betslip.stake = '';
      betslip.unitStake = '';
      betslip.editBetSingleStake = '';
    },
    /**
     * @name getWeightedRandom
     * @description returns "weighted" random element of array
     * @param {Array} array the array
     * @param {String} weightFieldName array's objects' field name that contains it's weight
     *
     * @return {Object} random weighted array item
     */
    getWeightedRandom: function getWeightedRandom(array, weightFieldName = 'weight') {
      var variants = [],
        i;
      array.forEach(function(item) {
        if (item.ignore) {
          return;
        }
        for (i = 0; i < (item[weightFieldName] || 1); i++) {
          variants.push(item);
        }
      });

      var index = Math.floor(Math.random() * variants.length);

      return variants[index];
    },
    cloneDeep: function(data) {
      return JSON.parse(JSON.stringify(data));
    },
    /**
     * @name byOrderSortingFunc
     * @description Function to be used by sort(), to sort by object's "order" property
     * @param a {Object}
     * @param b {Object}
     * @returns {number}
     */
    byOrderSortingFunc: function byOrderSortingFunc(a, b) {
      return a.order - b.order;
    },
    /**
     * @name byOrderSortingFunc
     * @description Function to be used by sort(), to sort by object's "order" property
     * @param a {Object}
     * @param b {Object}
     * @returns {number}
     */
    orderByFavoriteSortingFunc: function orderByFavoriteSortingFunc(a, b) {
      return a.favorite_order - b.favorite_order;
    },
    /**
     * @name orderByPrice
     * @description Function to be used by sort(), to sort by object's "price" property
     * @param a {Object}
     * @param b {Object}
     * @returns {number}
     */
    orderByPrice: function orderByPrice(a, b) {
      return a.price - b.price;
    },
    /**
     * @name descendingOrderByPrice
     * @description Function to be used by sort(), to sort by object's "price" property
     * @param a {Object}
     * @param b {Object}
     * @returns {number}
     */
    descendingOrderByPrice: function orderByPrice(a, b) {
      return b.price - a.price;
    },
    /**
     * @name orderByBase
     * @description Function to be used by sort(), to sort by object's "price" property
     * @param a {Object}
     * @param b {Object}
     * @returns {number}
     */
    orderByBase: function orderByBase(b, a) {
      return b.base - a.base;
    },
    /**
     * @name orderByName
     * @description Function to be used by sort(), to sort by object's "name" property
     * @param a {Object}
     * @param b {Object}
     * @returns {number}
     */
    orderByName: function orderByName(a, b) {
      const nameA = t(a.name || a.Name);
      const nameB = t(b.name || b.Name);
      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      }
      return 0;
    },
    /**
     * @name byStartTsSortingFunc
     * @description Function to be used by sort(), to sort by object's "start_ts" property
     * @param a {Object}
     * @param b {Object}
     * @returns {number}
     */
    byStartTsSortingFunc: function byStartTsSortingFunc(a, b) {
      if (a.start_ts === b.start_ts) {
        return a.id - b.id;
      }
      return a.start_ts - b.start_ts;
    },

    getSportOptionsByAlias: (filteredSports, sportAlias) =>
      Helpers.getArrayObjectElementHavingFieldValue(
        Object.values(filteredSports).flat(),
        'alias',
        sportAlias,
      ) || {},

    removeSPFromEventId: function removeSPFromEventId(eventId) {
      if (eventId && eventId.toString().includes('_sp')) {
        return Number(eventId.substring(0, eventId.indexOf('_sp')));
      }
      return eventId;
    },

    /**
     * @name createSortingFn
     * @description Creates sorting fynction to sort by provided field name
     * @param {String} field field name
     * @param {Boolean} ascending true to sort ascending, false for descending
     * @returns {function}
     */
    createSortingFn: function(field, ascending = true) {
      return (a, b) => (ascending ? a[field] - b[field] : b[field] - a[field]);
    },
    /**
     * @name objectToArray
     * @description Converts object to array
     * @param {Object} obj
     * @param {String} addKeyNameAsProperty if defined, object key names will be added to array elements with property of this name
     * @param {function} calculator if defined, callback will called for each item and will passed whole object for calculating purpose
     * @returns {Array}
     */
    objectToArray: function objectToArray(obj, addKeyNameAsProperty = null, calculator = null) {
      var arr = [];
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (addKeyNameAsProperty) {
            obj[key][addKeyNameAsProperty] = key;
          }
          calculator && calculator(obj[key]);
          arr.push(obj[key]);
        }
      }
      return arr;
    },
    /**
     * @name firstElement
     * @description Returns first element of an object
     * @param {Object} obj
     * @param {boolean|function} sortFunc function to sort object keys (false for no sorting). if not provided, will be sorted by
     * @returns {*}
     */
    firstElement: function firstElement(obj, sortFunc = null, key = null) {
      if (typeof obj !== 'object') {
        return null;
      }
      var keys = Object.keys(obj);
      if (!keys.length) {
        return null;
      }
      if (sortFunc === false) {
        return obj[keys[0]];
      }
      sortFunc = sortFunc || this.byOrderSortingFunc;
      return this.objectToArray(obj, key).sort(sortFunc)[0];
    },
    /**
     * @ngdoc method
     * @name MergeRecursive
     * @methodOf vbet5.service:Utils
     * @description merges 2 objects recursively
     * @param {Object} to destination object
     * @param {Object} from source object
     * @return {Object} returns changed destination object
     */
    mergeRecursive,

    /**
     * @name getArrayObjectElementHavingFieldValue
     * @description  returns array element object having field with specified value
     * @param {Array} array array of objects
     * @param {String} field the field name
     * @param {Object|String|Number} value field value
     * @return {Object|null}  object or null if not found
     */
    getArrayObjectElementHavingFieldValue: function getArrayObjectElementHavingFieldValue(
      array,
      field,
      value,
    ) {
      var i;
      for (i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
          return array[i];
        }
      }
      return null;
    },

    toBoolean: function(value) {
      try {
        return JSON.parse(value);
      } catch (err) {
        return undefined;
      }
    },
    /**
     * @name nl2br
     * @description converts newlines to <br> in given string
     * @param {String} str string to convert
     *
     * @return {String} string with <br>s instead of new lines
     */
    nl2br: function nl2br(str) {
      return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
    },
    /**
     * @description Checked Dates Diff
     * @param startTime
     * @param endTime
     * @returns {{years: number, months: number, days: number}}
     */
    checkDatesDiff: function(startTime, endTime) {
      let start = moment(startTime * 1000),
        end = moment(endTime * 1000),
        years,
        months,
        days;
      years = end.diff(start, 'year');
      start.add(years, 'year');
      months = end.diff(start, 'months');
      start.add(months, 'months');
      days = end.diff(start, 'days');
      return { years, months, days };
    },
    /**
     * @name isNumeric
     * @description Checks if argument is numeric
     * @param {*} n
     * @returns {boolean}
     */
    isNumeric: function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    /**
     * @name isEqual
     * @description Checks if argument is numeric
     * @param {object} obj1
     * @param {object} obj2
     * @returns {boolean}
     */
    isEqual: function isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    /**
     * @name isAndroid
     * @description Checks if operating system of device is android
     * @returns {boolean}
     */
    isAndroid: function() {
      return Helpers.getMobileOperatingSystem() === 'android';
    },
    /**
     * @name isIos
     * @description Checks if operating system of device is ios
     * @returns {boolean}
     */
    isIos: function() {
      return Helpers.getMobileOperatingSystem() === 'iphone';
    },
    /**
     * @name windows phone
     * @description Checks if operating system of device is windows phone
     * @returns {boolean}
     */
    isWindowsPhone: function() {
      return Helpers.getMobileOperatingSystem() === 'windows';
    },
    /**
     * @name isPC
     * @description Checks if operating system of device is PC
     * @returns {boolean}
     */
    isPC: function() {
      return (
        !Helpers.isIos() &&
        !Helpers.isAndroid() &&
        !Helpers.isWindowsPhone() &&
        window.innerWidth >= 980
      );
    },
    /**
     * @name tabletViewActive
     * @description Checks if is Tablet Version, only our project
     * @returns {boolean}
     */
    tabletViewActive: function() {
      // let orientation = screen.orientation || screen.mozOrientation || screen.msOrientation, orientationAngle = (orientation && orientation.angle) || window.orientation;
      return window.screen.width >= 980 || window.screen.height >= 980; //@TODO change
    },

    /**
     * @name getMobileOperatingSystem
     * @description Returns device operation system name or unknown
     * @returns {string}
     */
    getMobileOperatingSystem: function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'windows';
      }

      if (/android/i.test(userAgent)) {
        return 'android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iphone';
      }

      return 'unknown';
    },

    /**
     * @name getIPhoneVersion
     * @description Returns witch iphone version use user
     * @returns {string}
     */
    iPhoneVersion: function() {
      const { height, width } = window.screen;

      if (width === 414 && height === 896) {
        return 'Xmax-Xr';
      } else if (width === 375 && height === 812) {
        return 'X-Xs';
      } else if (width === 320 && height === 480) {
        return '4';
      } else if (width === 375 && height === 667) {
        return '6';
      } else if (width === 414 && height === 736) {
        return '6+';
      } else if (width === 320 && height === 568) {
        return '5';
      } else if (height <= 480) {
        return '2-3';
      }
      return 'none';
    },

    getTransactionType: function() {
      let { pathname } = window.location;
      switch (true) {
        case pathname.includes('balance/deposit'):
          return 'deposit';
        case pathname.includes('balance/withdraw'):
          return 'withdraw';
        default:
          return '';
      }
    },
    /**
     * @name setClassNameOnBody
     * @description set class on body if some parts of UI are opened
     * @param {Object} openedUIObj, UI opened object
     * @returns {Boolean}
     */
    setClassOnBodyUI: function(openedUIObj) {
      let availableClassNames = ['betslip', 'leftMenu', 'rightMenu'];
      for (let i in openedUIObj) {
        if (openedUIObj[i] && availableClassNames.includes(i)) {
          return true;
        }
      }
      return false;
    },

    /**
     * @name addClassName
     * @description It should add class name on the element
     * @param {string} selectors
     * @param {string} setClassName
     */
    addClassName: function(selectors, setClassName) {
      let element = document.querySelectorAll(selectors)[0];
      if (element && !element.className.includes(setClassName)) {
        element.classList.add(setClassName);
      }
    },

    /**
     * @name formatNumberWithCharacter
     * @description It changed number format (if you put 55566677 number and second parameter ',' number changed 555,666,77)
     * @param {number} number
     * @param {string} replaceFormat
     */
    formatNumberWithCharacter: function(number, replaceFormat = ',') {
      if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, replaceFormat);
      }
    },

    /**
     * @name removeClassName
     * @description It should remove class name on the element
     * @param {string} selectors
     * @param {string} setClassName
     */
    removeClassName: function(selectors, setClassName) {
      let element = document.querySelectorAll(selectors)[0];
      if (element && setClassName && element.className.includes(setClassName)) {
        element.classList.remove(setClassName);
      }
    },
    /**
     * @name memoizedAdd
     * @description memoized every array what criterion you want
     */
    memoizedAdd: (items = [], criterion) => {
      if (!cacheMenuItemsTitle.has(items)) {
        cacheMenuItemsTitle.set(
          items,
          items.map(item => item[criterion]),
        );
      }
      return cacheMenuItemsTitle.get(items);
    },

    /**
     * @name replaceClassName
     * @description It should check class name on the element
     * @param {string} selectors
     * @param {string} prevPath
     * @param {string} currentPath
     */
    replaceClassName: function(selectors, prevPath, currentPath) {
      if (prevPath !== currentPath) {
        Helpers.removeClassName(selectors, prevPath);
        Helpers.addClassName(selectors, currentPath);
      }
    },
    /**
     * @name existClassName
     * @description It should check class name on the element
     * @param {string} selectors
     * @param {string} setClassName
     */
    existClassName: function(selectors, setClassName) {
      let element = document.querySelectorAll(selectors)[0];
      return !!(element && element.className && element.className.includes(setClassName));
    },
    /**
     * @name getElementBySelector
     * @param selector
     * @param props
     * @returns {*}
     */
    getElementBySelector: function(selector, props) {
      let element = document.querySelector(selector);

      if (!(element && props)) return element;

      return props.map(prop => element[prop]);
    },
    /**
     * @name getHashParams
     * @description Returns location hash params
     * @returns {{}}
     */
    getHashParams: function getHashParams() {
      var hashParams = {};
      var e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^?/&;=]+)=?([^&;]*)/g,
        d = function(s) {
          return decodeURIComponent(s.replace(a, ' '));
        },
        q = window.location.hash.substring(1);
      // eslint-disable-next-line no-cond-assign
      while ((e = r.exec(q))) {
        hashParams[d(e[1])] = d(e[2]);
      }
      return hashParams;
    },
    /**
     * @name getQueryStringValue
     * @description Returns location query param
     * @param {String} name of the param
     * @param {String} url to get the param form given string default is current location
     * @returns {String}
     */
    getQueryStringValue: function(name, url) {
      if (!url) {
        url = decodeURIComponent(window.location.href);
      }
      name = name.replace(/[\[\]]/g, '\\$&');
      let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    /**
     * @name getUriParam
     * @description Returns hash or query param from location
     * @param {string} key
     * @returns {*}
     */
    getUriParam: function(key) {
      return Helpers.getQueryStringValue(key, null) || (Helpers.getHashParams() || {})[key];
    },

    removeQueryParams: function() {
      return window.history.replaceState(null, null, window.location.pathname);
    },

    /**
     * @name getObjectByStringPath
     * @description Returns part of the object o identified by path s
     * @param {Object} o source object
     * @param {String} s path (e.g. "data.messages")
     * @returns {*}
     */
    getObjectByStringPath(o, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, ''); // strip a leading dot
      var a = s.split('.');
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    },
    /**
     * @name CheckIfPathExists
     * @description Returns part of the object o identified by path s
     * @param {Object} o source object
     * @param {String} path (e.g. "data.messages")
     * @returns {boolean}
     */
    CheckIfPathExists(o, path) {
      try {
        return Boolean(Helpers.getObjectByStringPath(o, path));
      } catch (e) {
        return false;
      }
    },

    /**
     * @name generateQueryParamsFromObject
     * @description Returns uri encoded url
     * @param {object} obj
     * @param {string} url
     * @param {string} separator
     * @param {string} switchQueryToHash
     * @returns {string}
     */
    generateQueryParamsFromObject(obj, url = '', separator, switchQueryToHash) {
      return Object.keys(obj).reduce((str, key) => {
        if (str !== '') {
          str +=
            str.indexOf(switchQueryToHash || '?') === -1
              ? switchQueryToHash || '?'
              : separator || '&';
        }
        str += key + '=' + encodeURIComponent(obj[key]);
        return str;
      }, url);
    },

    /**
     * @name removeMatchingPartFromObject
     * @description Removes object defined by path, field name and value from the object
     * @param {Object} obj the source object
     * @param {String} path string path to iterable object inside obj (e.g. "data.messages.messages")
     * @param {String} field field name
     * @param {*} value field value to match
     */
    removeMatchingPartFromObject: function removeMatchingPartFromObject(obj, path, field, value) {
      let container = this.getObjectByStringPath(obj, path);
      if (Array.isArray(container)) {
        let index = container.reduce((acc, curr, ind) => (curr[field] === value ? ind : acc), null);
        index !== null && container.splice(index, 1);
      } else if (typeof container === 'object') {
        let key = Object.keys(container).reduce(
          (acc, curr) => (container[curr][field] === value ? curr : acc),
          null,
        );
        key !== null && delete container[key];
      }
    },
    /**
     * @name checkStatus
     * @description helper function to check request status and throw exception when something is wrong
     * @param {Object} response
     * @returns {Object | Undefined}
     */
    checkStatus: function(response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        let error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    },
    /**
     * This function parses string to xml.
     * @param xml: xml string to be converted.
     * @returns {String | undefined}
     */
    parseXml: function(xml) {
      let dom = null;
      if (window.DOMParser) {
        try {
          dom = new DOMParser().parseFromString(xml, 'text/xml');
        } catch (e) {
          dom = null;
        }
      } else if (window.ActiveXObject) {
        try {
          dom = new window.ActiveXObject('Microsoft.XMLDOM');
          dom.async = false;
          if (!dom.loadXML(xml)) {
            // parse error ..
            window.alert(dom.parseError.reason + dom.parseError.srcText);
          }
        } catch (e) {
          dom = null;
        }
      } else {
        console.log('cannot parse xml string!');
      }
      return dom;
    },
    /**
     * This function coverts a DOM Tree into JavaScript Object.
     * @param srcDOM: DOM Tree to be converted.
     * @returns {Object | Undefined}
     */
    xml2jsonWithAttrs: function(xml, tab) {
      let X = {
        toObj: function(xml) {
          let o = {};
          if (xml.nodeType === 1) {
            // element node ..
            if (xml.attributes.length) {
              // element with attributes  ..
              for (let i = 0; i < xml.attributes.length; i++) {
                o['_' + xml.attributes[i].nodeName] = (
                  xml.attributes[i].nodeValue || ''
                ).toString();
              }
            }
            if (xml.firstChild) {
              // element has child nodes ..
              let textChild = 0,
                cdataChild = 0,
                hasElementChild = false;
              for (let n = xml.firstChild; n; n = n.nextSibling) {
                if (n.nodeType === 1) hasElementChild = true;
                else if (
                  n.nodeType === 3 &&
                  // eslint-disable-next-line brace-style
                  n.nodeValue.match(/[^ \f\n\r\t\v]/)
                ) {
                  textChild++;
                }
                // non-whitespace text
                else if (n.nodeType === 4) cdataChild++; // cdata section node
              }
              if (hasElementChild) {
                if (textChild < 2 && cdataChild < 2) {
                  // structured element with evtl. a single text or/and cdata node ..
                  X.removeWhite(xml);
                  for (let n = xml.firstChild; n; n = n.nextSibling) {
                    if (n.nodeType === 3) {
                      o['#text'] = X.escape(n.nodeValue);
                    } else if (n.nodeType === 4) {
                      o['#cdata'] = X.escape(n.nodeValue);
                    } else if (o[n.nodeName]) {
                      // multiple occurence of element ..
                      if (o[n.nodeName] instanceof Array) {
                        o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                      } else {
                        o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                      }
                    } else {
                      o[n.nodeName] = X.toObj(n);
                    }
                  }
                } else {
                  // mixed content
                  if (!xml.attributes.length) {
                    o = X.escape(X.innerXml(xml));
                  } else {
                    o['#text'] = X.escape(X.innerXml(xml));
                  }
                }
              } else if (textChild) {
                // pure text
                if (!xml.attributes.length) {
                  o = X.escape(X.innerXml(xml));
                } else {
                  o['#text'] = X.escape(X.innerXml(xml));
                }
              } else if (cdataChild) {
                // cdata
                if (cdataChild > 1) {
                  o = X.escape(X.innerXml(xml));
                } else {
                  for (var n = xml.firstChild; n; n = n.nextSibling) {
                    o['#cdata'] = X.escape(n.nodeValue);
                  }
                }
              }
            }
            if (!xml.attributes.length && !xml.firstChild) o = null;
          } else if (xml.nodeType === 9) {
            // document.node
            o = X.toObj(xml.documentElement);
          } else {
            console.log('unhandled node type: ' + xml.nodeType);
          }
          return o;
        },
        toJson: function(o, name, ind) {
          let json = name ? '"' + name + '"' : '';
          if (o instanceof Array) {
            for (let i = 0, n = o.length; i < n; i++) {
              o[i] = X.toJson(o[i], '', ind + '\t');
            }
            json +=
              (name ? ':[' : '[') +
              (o.length > 1
                ? '\n' + ind + '\t' + o.join(',\n' + ind + '\t') + '\n' + ind
                : o.join('')) +
              ']';
          } else if (o == null) {
            json += (name && ':') + 'null';
          } else if (typeof o === 'object') {
            let arr = [];
            for (let m in o) {
              arr[arr.length] = X.toJson(o[m], m, ind + '\t');
            }
            json +=
              (name ? ':{' : '{') +
              (arr.length > 1
                ? '\n' + ind + '\t' + arr.join(',\n' + ind + '\t') + '\n' + ind
                : arr.join('')) +
              '}';
          } else if (typeof o === 'string') {
            json += (name && ':') + '"' + o.toString() + '"';
          } else {
            json += (name && ':') + o.toString();
          }
          return json;
        },
        innerXml: function(node) {
          let s = '';
          if ('innerHTML' in node) {
            s = node.innerHTML;
          } else {
            let asXml = function(n) {
              let s = '';
              if (n.nodeType === 1) {
                s += '<' + n.nodeName;
                for (let i = 0; i < n.attributes.length; i++) {
                  s +=
                    ' ' +
                    n.attributes[i].nodeName +
                    '="' +
                    (n.attributes[i].nodeValue || '').toString() +
                    '"';
                }
                if (n.firstChild) {
                  s += '>';
                  for (var c = n.firstChild; c; c = c.nextSibling) {
                    s += asXml(c);
                  }
                  s += '</' + n.nodeName + '>';
                } else {
                  s += '/>';
                }
              } else if (n.nodeType === 3) {
                s += n.nodeValue;
              } else if (n.nodeType === 4) {
                s += '<![CDATA[' + n.nodeValue + ']]>';
              }
              return s;
            };
            for (let c = node.firstChild; c; c = c.nextSibling) {
              s += asXml(c);
            }
          }
          return s;
        },
        escape: function(txt) {
          return txt
            .replace(/[\\]/g, '\\\\')
            .replace(/[\"]/g, '\\"')
            .replace(/[\n]/g, '\\n')
            .replace(/[\r]/g, '\\r');
        },
        removeWhite: function(e) {
          e.normalize();
          for (let n = e.firstChild; n; ) {
            if (n.nodeType === 3) {
              // text node
              if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
                // pure whitespace text node
                let nxt = n.nextSibling;
                e.removeChild(n);
                n = nxt;
              } else {
                n = n.nextSibling;
              }
            } else if (n.nodeType === 1) {
              // element node
              X.removeWhite(n);
              n = n.nextSibling;
            } else {
              n = n.nextSibling;
            }
          }
          return e;
        },
      };
      if (xml.nodeType === 9) {
        xml = xml.documentElement;
      }
      let json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, '\t');
      return '{\n' + tab + json.replace(/\t|\n/g, '') + '\n}';
    },
    /**
     * @name convertBalance
     * @description helper function remove numbers ,if after dot numbers lenght > 2
     * @param {number} value
     * @returns {number}
     */
    convertBalance: function(value) {
      value = Math.floor(value * 100) / 100;
      return value;
    },
    /**
     * @name disableDefaultScroll
     * @description disable Default Scroll
     * @param {DOM element} scrolledElement
     * @param {boolean} enableListeners
     */
    disableDefaultScroll: function(scrolledElement, enableListeners = true) {
      let handleScrollEvent = event => {
          if (
            scrolledElement.scrollTop ===
            scrolledElement.scrollHeight - scrolledElement.clientHeight
          ) {
            scrolledElement.scrollTop =
              scrolledElement.scrollHeight - scrolledElement.clientHeight - 1;
          }
          if (scrolledElement.scrollTop === 0) {
            scrolledElement.scrollTop = 1;
          }
          scrolledElement.allowUp = scrolledElement.scrollTop > 0;
          scrolledElement.allowDown =
            scrolledElement.scrollTop < scrolledElement.scrollHeight - scrolledElement.clientHeight;
          scrolledElement.lastY = event.pageY;

          let up = event.pageY > scrolledElement.lastY,
            down = !up;
          scrolledElement.lastY = event.pageY;
          if ((up && scrolledElement.allowUp) || (down && scrolledElement.allowDown)) {
            event.stopPropagation();
          } else {
            event.preventDefault();
          }
        },
        handleTouchMoveEvent = event => {
          if (scrolledElement.clientHeight === scrolledElement.scrollHeight) {
            event.preventDefault();
          }
        };

      if (scrolledElement) {
        if (!enableListeners) {
          scrolledElement.removeEventListener('touchmove', handleTouchMoveEvent);
          scrolledElement.removeEventListener('scroll', handleScrollEvent);
        } else {
          scrolledElement.addEventListener('touchmove', handleTouchMoveEvent);
          scrolledElement.addEventListener('scroll', handleScrollEvent);
        }
      }
    },
    thousandSeparator: function(value) {
      if (!Config.main.withThousandSeparator) {
        return value;
      }
      let sValue = value.toString(),
        newValue;
      if (sValue.includes('.')) {
        let fValue = sValue.split('.');
        let fVal1 = fValue[0].replace(
            /\B(?=(\d{3})+(?!\d))/g,
            Config.main.commaSeparator ? '.' : ',',
          ),
          fVal2 = fValue[1];
        newValue = fVal1 + (Config.main.commaSeparator ? ',' : '.') + fVal2;
      } else {
        newValue = sValue.replace(/\B(?=(\d{3})+(?!\d))/g, Config.main.commaSeparator ? '.' : ',');
      }
      return newValue;
    },
    getRouteType: function(pathname) {
      return {
        casino: 'casino',
        'live-casino': 'casino',
        games: 'casino',
        'free-quiz': 'casino',
        game: 'sport',
        live: 'sport',
        prematch: 'sport',
        poker: 'zharmar',
        asian_prematch: 'sport',
        asian_live: 'sport',
        asian_today: 'sport',
        '': 'home',
      }[pathname.split('/')[1]];
    },
    /**
     * @name amountNormalizer
     * @description Helper function for validation
     * @param {number|string} value
     * @returns {number|string}
     */
    amountNormalizer: (value, oldValue) => {
      if (
        value &&
        ('' + value).length > 1 &&
        ('' + value).match(/^0/) &&
        ('' + value).indexOf('.') !== 1
      ) {
        value = value.split('');
        value = value[1];
      }
      return isNaN(value) ? oldValue : value || '';
    },
    /**
     * @name arrayToObject
     * @description Helper function for validation
     * @param {number|string} array
     * @returns {number|string}
     */
    arrayToObject: array => {
      return array.reduce(function(acc, cur, i) {
        acc[i] = cur;
        return acc;
      }, {});
    },
    placeNumber: function(number) {
      switch (number) {
        case 1:
          return '1st';
        case 2:
          return '2nd';
        case 3:
          return '3rd';
        default:
          return number + 'th';
      }
    },
    convertDateToUTC: function(date, format = 'YYYY.MM.DD HH:mm') {
      return moment
        .utc(date)
        .local()
        .format(format);
    },

    hideLeftMenuForSpecificRoutes: function(pathname) {
      let routes = [
        // "/promotions",
        // "/free-quiz",
        // "/poker",
        // "/casino/tournaments"
      ];
      return routes.includes(pathname);
    },

    disableKeyCode: function(event, keyCodes) {
      let charCode = event.which || event.keyCode;
      return keyCodes.includes(charCode) && event.preventDefault();
    },
    openPDF: function(base64Code, title) {
      let { availWidth, availHeight } = window.screen,
        pdfWindow = window.open(
          '',
          '',
          `width=${availWidth}, height=${availHeight}, toolbar=0, menubar=0, location=0, status=0, scrollbars=1, resizable=0, left=0, top=0`,
        ),
        pdfDocument = pdfWindow.document,
        e,
        f,
        g;
      for (e = atob(base64Code), f = [], g = 0; g < e.length; g++) {
        f[g] = e.charCodeAt(g);
      }
      e = new Uint8Array(f);
      e = new Blob([e], {
        type: 'application/pdf',
      });
      e = URL.createObjectURL(e);
      pdfDocument.write(
        '<body style="margin:0px;padding:0px;overflow:hidden"><iframe style="overflow:hidden;height:100%;width:100%" height="100%" width="100%" src="' +
          e +
          '" /></body>',
      );
      pdfDocument.title = title;
    },
    reverseString: function(string, symbolList = []) {
      let s = '',
        multyDigits = '';
      for (let i = string.length - 1; i >= 0; i--) {
        if (symbolList.length && symbolList.includes(string[i]) && symbolsReverseList[string[i]]) {
          s += multyDigits;
          multyDigits = '';
          s += symbolsReverseList[string[i]];
          continue;
        }
        if (string[i] && !isNaN(string[i])) {
          let tmp = multyDigits;
          multyDigits = string[i];
          multyDigits += tmp;
          continue;
        }
        s += multyDigits;
        s += string[i];
        multyDigits = '';
      }
      s += multyDigits;
      return s;
    },
    /**
     * @name orderBetHistoryOptions
     * @description Helper function for changing the bet history period order
     * @param {Array} predefinedDateRanges
     * @param {Array} orderBetHistory
     * @returns {Array} ordered array of history options
     */
    orderBetHistoryOptions: function(predefinedDateRanges, orderBetHistory) {
      let collection = [];
      for (let i = 0; i < predefinedDateRanges.length; i++) {
        for (let j = 0; j < orderBetHistory.length; j++) {
          if (predefinedDateRanges[j].key === orderBetHistory[i]) {
            collection.push(predefinedDateRanges[j]);
          }
        }
      }
      return collection;
    },
    /**
     * @name createStylesheetLink
     * @description Helper function for changing skin's stylesheet link
     * @param {string} color
     * @param {function} dispatch
     */
    createStylesheetLink: function(color, dispatch) {
      dispatch(AdditionalCssLoaded(false));
      let fileref = document.createElement('link'),
        oldStyles = document.getElementsByTagName('link'),
        head = document.getElementsByTagName('head')[0];

      for (let i = 0; i < oldStyles.length; ++i) {
        if (oldStyles[i].getAttribute('rel') === 'stylesheet') {
          oldStyles[i].parentNode.removeChild(oldStyles[i]);
          --i;
        }
      }
      fileref.setAttribute('rel', 'stylesheet');
      fileref.setAttribute('type', 'text/css');
      fileref.setAttribute('id', 'appColor');
      fileref.setAttribute('href', '/' + color + '.css');
      fileref.onload = function() {
        dispatch(AdditionalCssLoaded(true));
      };
      head.appendChild(fileref);
    },

    guid: function() {
      /** UUID generator
       * Function provides "unique" id.
       * Must be used with React iterable components.
       */

      const hash = () =>
        Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);

      /** @function guid
       * @description
       * The function generates uuid. It can be used as `key`-s needed for
       * React iterated components
       */

      return (
        hash() +
        hash() +
        '-' +
        hash() +
        '-' +
        hash() +
        '-' +
        hash() +
        '-' +
        hash() +
        hash() +
        hash()
      );
    },
    regExpReplacer(str = '', replaceSpaces = true) {
      return replaceSpaces ? str.replace(/ /g, '_') : str.replace(/_/g, ' ');
    },
    snakeCaseToCamelCase(string = '', firstCharUpper) {
      string = firstCharUpper ? string.charAt(0).toUpperCase() + string.slice(1) : string;
      return string.replace(/_\w/g, m => m[1].toUpperCase());
    },
    copyTextToClipboard: function(string, callBack) {
      if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData('Text', string);
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        let textArea = document.createElement('textarea');
        textArea.textContent = string;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        // different devices (ios, android, etc) copy functionality works differently
        // for this we call both methods
        textArea.select();
        textArea.setSelectionRange(0, 9999);
        try {
          return document.execCommand('copy');
        } catch (errMsg) {
          callBack && callBack({ err: true, errMsg });
          return false;
        } finally {
          document.body.removeChild(textArea);
          callBack && callBack({ err: false });
        }
      }
    },
    buildQRCodeParams: function(string = '') {
      if (!string) return {};
      let secretSearchKey = 'secret=',
        googleQRCodeImgPath = 'https://chart.googleapis.com/chart?chs=134x134&chld=L|0&cht=qr&chl=',
        secretIndex = string.indexOf(secretSearchKey) + secretSearchKey.length,
        secretKey = string.slice(secretIndex, string.length).slice(0, 32);
      return {
        secretKey,
        qrCodeImgUrl: `${googleQRCodeImgPath}${window.encodeURIComponent(string)}`,
      };
    },

    prepareReccomenderWidgetData: betslipEvents => {
      return Object.entries(betslipEvents).reduce((acc, [eventId, info]) => {
        acc[eventId] = {
          oddType: info.eventType,
          selected: true,
        };
        return acc;
      }, {});
    },
  };
})();

export default Helpers;
