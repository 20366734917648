export default function mergeRecursive(to, from) {
  var p;
  for (p in from) {
    if (from.hasOwnProperty(p)) {
      try {
        if (from[p].constructor === Object) {
          if (from[p]['@replace'] === true) {
            //replace field instead of merging if specified
            to[p] = from[p];
            delete to[p]['@replace'];
          } else {
            to[p] = mergeRecursive(to[p], from[p]);
          }
        } else {
          to[p] = from[p];
        }
      } catch (e) {
        to[p] = from[p];
      }
    }
  }
  return to;
}
