// Index file to get config file from cms or from local file,
// merge with global configs and only then load the app
import React from 'react';
import ReactDOM from 'react-dom';

import globalConfig from 'config/main';
import countries from 'constants/countries';
import Helpers from 'helpers/helperFunctions';
import mergeRecursive from 'helpers/mergeRecursive';
import ThemingSplashScreen from 'mobile/components/themingSplashScreen';
import 'assets/scss/compile.scss';

const skinConfig = require(`skins/${process.env.REACT_APP_SKIN}/config.js`);

const { main: globalMain } = globalConfig;
const {
  cms: { configFromCmsLink } = {},
  main: { additionalPayments = [], loadConfigsFromCms, regConfig, site_id: siteId },
} = skinConfig;

if (process.env.NODE_ENV === 'production') {
  function noop() {}

  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(<ThemingSplashScreen />, document.getElementById('root'));

const loadConfig = new Promise(async resolve => {
  if (loadConfigsFromCms) {
    // Load external configs
    // Note:: second check do because CMS don't have staging environment for configs call.
    const configUrl =
      process.env.NODE_ENV === 'development' ||
      window.location.hostname.includes('mobilestaging.betconstruct.com')
        ? `https://cmsbetconstruct.com/general/getMobileConfJson?skin_id=${siteId}&v=${Date.now()}`
        : `/conf.json?v=${Date.now()}`;

    const res = await fetch(configUrl);
    const cmsConfig = await res.json();
    const { main } = cmsConfig;

    const regSettingsInCMS = main.regConfig && main.regConfig.settings;
    main.regConfig = regConfig;

    if (regSettingsInCMS) {
      main.regConfig.settings = regSettingsInCMS;
    }
    if (main.additionalPayments) {
      main.payments = [...(main.payments || []), ...main.additionalPayments];
    }

    resolve(cmsConfig);
    console.info('Config loaded from cms');
  } else {
    // Get payments from CMS then load local configs
    if (configFromCmsLink) {
      const res = await fetch(configFromCmsLink);
      const successfulRes = await Helpers.checkStatus(res);
      const { SkinConfig: { payments: configPayment } = {} } = await successfulRes.json();
      const payments = configPayment || [];

      skinConfig.main.payments = [...payments, ...additionalPayments];
    }

    resolve(skinConfig);
    console.info('Config loaded from local skin config');
  }
});

loadConfig
  .then(config => {
    if (!globalMain.getPaymentsFromSwarm) {
      const transactionList = ['deposit', 'withdraw'];
      const {
        main: { payments = [] },
      } = config;
      const paymentsByCurrency =
        payments &&
        payments.reduce((acc, payment) => {
          transactionList.forEach(transaction => {
            const depositOrWithdraw = acc[transaction] || {};
            const depositOrWithdrawPayment = payment[`${transaction}Currencies`] || [];

            depositOrWithdrawPayment.forEach(currency => {
              depositOrWithdraw[currency] = depositOrWithdraw[currency] || [];
              !depositOrWithdraw[currency].includes(payment.name) &&
                depositOrWithdraw[currency].push(payment.name);
            });

            acc[transaction] = depositOrWithdraw;
          });
          return acc;
        }, {});

      globalMain.paymentByCurrency = paymentsByCurrency;
    }

    // merge with global configs
    mergeRecursive(globalConfig, config);

    // if (globalConfig.main.trackingLogs) {
    //   import('helpers/tracking');
    // }

    return import('./app.js');
  })
  .catch(console.error);

/**
 * Set application language.
 */
const lang = (
  (JSON.parse(localStorage.getItem('reduxPersist:preferences')) || {}).lang || ''
).toLocaleUpperCase();

const browserLng = (Object.keys(countries.iso2ToIso3).filter(
  item => countries.iso2ToIso3[item] === lang,
) || [])[0];

const html = document.querySelector('html');
browserLng && (html.lang = browserLng.toLocaleLowerCase());
