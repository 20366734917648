import {
  PREMATCH_TIME_FILTER,
  PREMATCH_WIDGET_TIME_FILTER,
  UI_COLLAPSE_ELEMENT,
  UI_EXPAND_ELEMENT,
  UI_OPEN,
  UI_CLOSE,
  TOGGLE_VIRTUAL_KEYBOARD,
  OPEN_POPUP,
  ZIMPLER_SEEN,
  CLOSE_POPUP,
  UI_LAST_ROUTE_TYPE,
  UI_CURRENT_ROUTE_TYPE,
  UI_PREVIOUS_PATH,
  CONFIRMATION_DIALOG_ANSWER,
  CONFIRMATION_DIALOG_RESET,
  UI_LOADING,
  UI_LOADING_DONE,
  UI_LOADING_FAILED,
  UI_LOADING_RESET,
  LIVE_VIDEO_FILTER,
  SET_GAME_INFO_FOR_VIDEO,
  HIDE_ANDROID_APP_DOWNLOAD_POPUP,
  HIDE_CASINO_ANDROID_APP_DOWNLOAD_POPUP,
  SET_MARKET_GROUP_FILTER,
  OPEN_EXTERNAL_POPUP,
  GDPR_PASSED_DONE,
  LOAD_EXTERNAL_SCRIPT,
  ADDITIONAL_CSS_LOADED,
  GAME_SELECTED,
  DISABLE_AUTHENTICATION_BUTTON,
  UI_DISABLE_BLUR,
  UI_ENABLE_BLUR,
  SESSION_OPENED,
} from './actionTypes';

/**
 * @name HideAndroidDownloadPopup
 * @description sync action creator function.
 * @returns {Object}
 */
export const HideAndroidDownloadPopup = () => {
  /**
   * @event hideAppDownloadPopup
   * */
  return {
    type: HIDE_ANDROID_APP_DOWNLOAD_POPUP,
  };
};
export const HideCasinoAndroidDownloadPopup = () => {
  return {
    type: HIDE_CASINO_ANDROID_APP_DOWNLOAD_POPUP,
  };
};

/**
 * @name PrematchTimeFilter
 * @description sync action creator function.
 * @param {object} payload
 * @returns {Object}
 */
export const PrematchTimeFilter = payload => {
  /**
   * @event prematchTimeFilter
   * */
  return {
    type: PREMATCH_TIME_FILTER,
    payload,
  };
};

/**
 * @name LiveVideoFilter
 * @description sync action creator function.
 * @param {object} payload
 * @returns {Object}
 */
export const LiveVideoFilter = payload => {
  /**
   * @event changeLiveVideoFilter
   * */
  return {
    type: LIVE_VIDEO_FILTER,
    payload,
  };
};

/**
 * @name VirtualVideoData
 * @description sync action creator function, info that you load virtual video.
 * @param {object} payload
 * @returns {Object}
 */
export const VirtualVideoData = payload => {
  /**
   * @event VirtualVideoFilter
   * */
  return {
    type: SET_GAME_INFO_FOR_VIDEO,
    payload,
  };
};

/**
 * @name LiveVideoFilter
 * @description sync action creator function.
 * @param {object} payload
 * @returns {Object}
 */
export const MarketFilter = payload => {
  /**
   * @event changeLiveVideoFilter
   * */
  return {
    type: SET_MARKET_GROUP_FILTER,
    payload,
  };
};

/**
 * @name PrematchWidgetTimeFilter
 * @description sync action creator function.
 * @param {object} message
 * @returns {Object}
 */
export const PrematchWidgetTimeFilter = message => {
  /**
   * @event changeWidgetFilter
   * */
  return {
    type: PREMATCH_WIDGET_TIME_FILTER,
    payload: message,
  };
};

/**
 * @name UICollapseElement
 * @description sync action creator function.
 * Each expandable component must have unique key
 * @param {object} key
 * @returns {Object}
 */
export const UICollapseElement = key => {
  /**
   * @event collapseComponent
   * */
  return {
    type: UI_COLLAPSE_ELEMENT,
    payload: key,
  };
};

/**
 * @name UIExpandElement
 * @description sync action creator function.
 * Each expandable component must have unique key
 * @param {object} key
 * @returns {Object}
 */
export const UIExpandElement = key => {
  /**
   * @event expandComponent
   * */
  return {
    type: UI_EXPAND_ELEMENT,
    payload: key,
  };
};

/**
 * @name UIOpen
 * @description sync action creator function.
 * Open ui component
 * @param {object} key
 * @param {string} payload
 * @returns {Object}
 */
export const UIOpen = (key, payload) => {
  /**
   * @event uiOpen
   * */
  return {
    type: UI_OPEN,
    key,
    payload,
  };
};

/**
 * @name SelectedTabName
 * @description sync action creator function.
 * add selected game name in redux state
 * @param {object} key
 * @returns {Object}
 */
export const SelectedTabName = (key = '') => {
  return {
    type: GAME_SELECTED,
    key,
  };
};

/**
 * @name UIClose
 * @description sync action creator function.
 * Close opened component
 * @param {object} key
 * @returns {Object}
 */
export const UIClose = key => {
  /**
   * @event uiClose
   * */
  return {
    type: UI_CLOSE,
    key,
  };
};

/**
 * @name UIToggleVirtualKeyboard
 * @description sync action creator function.
 * Close Virtual Keyboard component
 * @param {object} payload
 * @returns {Object}
 */
export const UIToggleVirtualKeyboard = payload => {
  /**
   * @event UICloseAndOpenVirtualKeyboard
   * */
  return {
    type: TOGGLE_VIRTUAL_KEYBOARD,
    payload,
  };
};

/**
 * @name UILoading
 * @description sync action creator function.
 * Loading started
 * @param {object} key
 * @returns {Object}
 */
export const UILoading = key => {
  /**
   * @event uiLoadingStart
   * */
  return {
    type: UI_LOADING,
    key,
  };
};

/**
 * @name UILoadingDone
 * @description sync action creator function.
 * Loading finished
 * @param {object} key
 * @returns {Object}
 */
export const UILoadingDone = key => {
  /**
   * @event uiLoadingDone
   * */
  return {
    type: UI_LOADING_DONE,
    key,
  };
};

/**
 * @name UILoadingDone
 * @description sync action creator function.
 * Loading finished
 * @param {object} key
 * @returns {Object}
 */
export const GDPRPassedDone = key => {
  /**
   * @event GDPRPassedDone
   * */
  return {
    type: GDPR_PASSED_DONE,
    key,
  };
};

/**
 * @name UILoadingFailed
 * @description sync action creator function.
 * Loading filed and the file reason
 * @param {object} key
 * @param {object} reason
 * @returns {Object}
 */
export const UILoadingFailed = (key, reason) => {
  /**
   * @event uiLoadingFail
   */
  return {
    type: UI_LOADING_FAILED,
    key,
    reason,
  };
};

/**
 * @name UILoadingReset
 * @description sync action creator function.
 * Clear loading data
 * @param {object} key
 * @returns {Object}
 */
export const UILoadingReset = key => {
  /**
   * @event uiLoadingReset
   */
  return {
    type: UI_LOADING_RESET,
    key,
  };
};

/**
 * @name OpenPopup
 * @description sync action creator function.
 * To open popup and pass some payload.
 * It's impossible to open multiple popups at the same time.
 * @param {object} key
 * @param {*} payload
 * @returns {Object}
 */
export const OpenPopup = (key, payload) => {
  /**
   * @event openPopup
   */
  return {
    type: OPEN_POPUP,
    key,
    payload,
  };
};

/**
 * @name DisableButton
 * @description sync action creator function.
 * Disable Button.
 * If from backend turn off 2FA.
 * @returns {Object}
 */

export const DisableButton = key => ({
  type: DISABLE_AUTHENTICATION_BUTTON,
  key,
});

/**
 * @name ClosePopup
 * @description sync action creator function.
 * To close opened.
 * It's impossible to open multiple popups at the same time.
 * @returns {Object}
 */
export const ClosePopup = locationChanged => {
  /**
   * @event closePopup
   */
  return {
    type: CLOSE_POPUP,
    locationChanged,
  };
};

export const LoadExternalScript = () => {
  return {
    type: LOAD_EXTERNAL_SCRIPT,
  };
};
/**
 * @name ConfirmationDialogAnswer
 * @description sync action creator function.
 * To close Confirmation dialog.
 * It's impossible to open multiple dialogs at the same time.
 * @param {string} key
 * @param {*} answer
 * @param {object} payload
 * @returns {Object}
 */
export const ConfirmationDialogAnswer = (key, answer, payload) => {
  /**
   * @event closeConfirmationDialog
   */
  return {
    type: CONFIRMATION_DIALOG_ANSWER,
    key,
    answer,
    payload,
  };
};

export const OpenExternalPopup = () => {
  return {
    type: OPEN_EXTERNAL_POPUP,
  };
};

/**
 * @name ConfirmationDialogReset
 * @description sync action creator function.
 * To reset Confirmation dialog data in redux storage.
 * @param {string} key
 * @returns {Object}
 */
export const ConfirmationDialogReset = key => {
  /**
   * @event resetConfirmationDialog
   */
  return {
    type: CONFIRMATION_DIALOG_RESET,
    key,
  };
};

/**
 * @name UISetLastRouteType
 * @description sync action creator function.
 * To set last route type for example sport or casino.
 * @returns {Object} routeType
 */
export const UISetLastRouteType = routeType => {
  /**
   * @event setLastRouteType
   */
  return {
    type: UI_LAST_ROUTE_TYPE,
    routeType,
  };
};

/**
 * @name UISetPreviousPath
 * @description sync action creator function.
 * To set previous route path for example history/bets.
 * @param {string} path
 * @returns {Object}
 */
export const UISetPreviousPath = path => {
  /**
   * @event setLastPreviousPath
   */
  return {
    type: UI_PREVIOUS_PATH,
    path,
  };
};

/**
 * @name UISetCurrentRouteType
 * @description sync action creator function.
 * To set current route type for example sport or casino
 * @returns {Object} routeType
 */
export const UISetCurrentRouteType = routeType => {
  /**
   * @event setCurrentRouteType
   */
  return {
    type: UI_CURRENT_ROUTE_TYPE,
    routeType,
  };
};
/**
 * @name AdditionalCssLoaded
 * @description sync action creator function.
 * To set additional css was loaded or not
 * @returns {Object} new State
 */

export const AdditionalCssLoaded = payload => ({
  type: ADDITIONAL_CSS_LOADED,
  payload,
});

export const DisableBlur = () => ({
  type: UI_DISABLE_BLUR,
  blurEnabled: false,
});

export const EnableBlur = () => {
  return {
    type: UI_ENABLE_BLUR,
    blurEnabled: true,
  };
};

export const ZimplerDataIFramePassed = payload => ({ type: ZIMPLER_SEEN, payload });

export const setSessionOpened = payload => ({ type: SESSION_OPENED, payload });
